@charset "UTF-8";

//
// Styles for rendered markdown in the .main-content container
//
// stylelint-disable selector-no-type, max-nesting-depth, selector-max-compound-selectors, selector-max-type

.page-content {
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ul,
  ol {
    padding-left: 1.5em;
  }

  ol {
    list-style-type: none;
    counter-reset: step-counter;

    > li {
      position: relative;

      &::before {
        position: absolute;
        top: 0.2em;
        left: -1.6em;
        color: $grey-dk-000;
        content: counter(step-counter);
        counter-increment: step-counter;
        @include fs-3;

        @include mq(sm) {
          top: 0.11em;
        }
      }

      ol {
        counter-reset: sub-counter;

        li {
          &::before {
            content: counter(sub-counter, lower-alpha);
            counter-increment: sub-counter;
          }
        }
      }
    }
  }

  div.article-container{
    display: flex;
    height: auto;
    padding: 0;
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;

    > div.article-list{
      //width: calc(100% / 3);
      width: 18em;
      height: auto;
      flex-basis: auto;

      > div.article-category{
        position: relative;
        background-color: $link-color;
        color: #ffffff;
        text-align: center;
        border-top-left-radius: .25em;
        border-top-right-radius: .25em;
        margin: .5em .5em 0 .5em;
        padding: .75em;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.18);
        overflow: hidden;

        > div.language-indicator{
          position: absolute;
          top: -12px;
          right: -24px;
          width: 100px;
          text-align: center;
          margin: 0;
          padding: 2em 0 0 1em;
          -ms-transform: rotate(35deg); /* IE 9 */
          -webkit-transform: rotate(35deg); /* Safari 3-8 */
          transform: rotate(35deg);
        }

        > div.lang-en{
          font-size: 70%;
          background-color: #000050;
          color: white;
        }

        > div.lang-es{
          font-size: 70%;
          background-color: #ffc400;
          color: black;
        }

        > div.lang-pt{
          font-size: 70%;
          background-color: #006600;
          color: white;
        }

        > div.lang-kr{
          font-size: 70%;
          background-color: #0047a0;
          color: white;
          font-family: 'Nanum Gothic', Arial, sans-serif;
        }

        > div.lang-cn{
          font-size: 70%;
          background-color: #df1b12;
          color: white;
          font-family: Arial, Helvetica, tahoma, verdana, 'Noto Sans SC', 宋体, SimSun, 华文细黑, STXihei, sans-serif;
        }

        > div.lang-tw{
          font-size: 70%;
          background-color: #000097;
          color: white;
          font-family: Arial, Helvetica, tahoma, verdana, 'Noto Sans TC', '宋體', SimSun, sans-serif;
        }

      }

      > div.article-summary{
        border-bottom-left-radius: .25em;
        border-bottom-right-radius: .25em;
        background-color: white;
        padding: 1em;
        margin: 0 .5em .5em .5em;
        line-height: 1em;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.18);

        > a{
          white-space: normal;
        }
      }
    }
  }

  ul.article-container {
    list-style: none;

    display: flex;
    height: auto;
    padding: 0;
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    > li.article-list {
      display: inline-block;
      border: .25em solid grey;
      border-radius: .5em;
      background-color: white;
      padding: 1em;
      margin: .5em;
      //width: calc(100% / 3);
      width: 18em;
      height: auto;
      line-height: 1em;
      flex-basis: auto;

      > div.article-category{
        background-color: black;
      }

      > a{
        white-space: normal;
      }
    }
  }

  .task-list {
    padding-left: 0;
  }

  .task-list-item {
    display: flex;
    align-items: center;

    &::before {
      content: "";
    }
  }

  .task-list-item-checkbox {
    margin-right: 0.6em;
  }

  hr + * {
    margin-top: 0;
  }

  h1:first-of-type {
    margin-top: 0.5em;
  }

  dl {
    display: grid;
    grid-template-columns: max-content 1fr;
  }

  dt,
  dd {
    margin: 0.25em 0;
  }

  dt {
    text-align: right;

    &::after {
      content: ":";
    }
  }

  dd {
    margin-left: 1em;
    font-weight: 500;
  }
}
