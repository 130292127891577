//
// Search input and autocomplete
//

.search {
  position: relative;
  z-index: 99;
  display: none;
  flex-grow: 1;
  padding: $sp-2;
  margin-bottom: $sp-3;
  background-color: $white;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 3px 10px rgba(0, 0, 0, 0.05);

  @include mq(md) {
    display: block;
    padding-top: $sp-1;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-bottom: 0;
    background-color: transparent;
    box-shadow: none;
  }

  &.nav-open {
    display: block;
  }
}

.search-results-wrap {
  display: none;

  &.active {
    position: absolute;
    top: $sp-1;
    z-index: 100;
    display: block;
    width: 300px;
    margin-top: $gutter-spacing;
    background: lighten($body-background-color, 1%);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07), 0 4px 14px rgba(0, 0, 0, 0.05);
  }
}

.search-input-wrap {
  display: flex;
  background-color: $body-background-color;
}

.search-input {
  width: 100%;
  padding-top: $sp-1;
  padding-bottom: $sp-1;
  background-color: $body-background-color;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  order: 2;

  &:focus {
    outline: 0;
    box-shadow: none;

    + .search-icon {
      fill: $link-color;
    }
  }

  @include fs-5;

  @include mq(sm) {
    @include fs-3;
  }

  @include mq(md) {
    @include fs-2;
  }
}

.search-icon {
  align-self: center;
  margin-right: $sp-2;
  fill: $grey-dk-000;
  order: 1;
}

.search-results-list {
  padding-left: 0;
  margin-top: $sp-1;
  margin-bottom: $sp-1;
  list-style: none;
  @include fs-3;
}

.search-results-list-item {
  padding: 0;
  margin: 0;
}

.search-results-link {
  display: block;
  padding-top: $sp-1;
  padding-right: $sp-3;
  padding-bottom: $sp-1;
  padding-left: $sp-3;

  &:hover {
    color: $body-heading-color;
    background-color: darken($body-background-color, 2%);
  }
}
