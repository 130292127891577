//
// The basic two column layout
//

.page-wrap {
  @include mq(md) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.side-bar {
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  // padding-top: $gutter-spacing-sm;
  padding-bottom: $gutter-spacing-sm;
  background-color: $sidebar-color;

  @include mq(md) {
    flex-wrap: wrap;
    position: absolute;
    // width: $nav-width + 16px;
    max-width: 350px;
    height: 100%;
    // padding-top: $gutter-spacing * 2;
    padding-bottom: 0;
    flex-direction: column;
    // border-right: $border $border-color;
    align-items: flex-end;
  }

  @include mq(lg) {
    // width: calc((100% - #{$nav-width + $content-width}) / 2 + #{$nav-width});
    max-width: 350px;
    // min-width: $nav-width;
  }
}

.main-content-wrap {
  @include mq(md) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 600px;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.main-content {
  padding-top: $gutter-spacing-sm;
  @include container;

  @include mq(md) {
    position: relative;
    //max-width: $content-width;
    // max-width: 80%;
    max-width: 1250px;
    padding-top: $gutter-spacing;
    padding-bottom: $gutter-spacing;
    // padding-left: $gutter-spacing * 1.5;
    padding-left: $gutter-spacing * 1;
    // margin-left: $nav-width;
    margin-left: 350px;
  }

  @include mq(lg) {
    padding-left: $gutter-spacing;
    // margin-left: calc((100% - #{$nav-width + $content-width}) / 2 + #{$nav-width});
    margin-left: 350px;
  }
}

.js-main-content:focus {
  outline: none;
}

.page-header {
  background-color: $sidebar-color;

  @include mq(md) {
    background-color: $body-background-color;
  }

  .main-content {
    padding-top: 0;

    @include mq(md) {
      display: flex;
      justify-content: flex-end;
      height: 60px;
      padding-top: $sp-4;
      padding-bottom: $sp-4;
      border-bottom: $border $border-color;
    }
  }
}

.navigation,
.site-title,
.site-footer {

  @include container;

  width: 100%;

  @include mq(lg) {
    width: $nav-width + 32px;
  }
}

.navigation {
  @include mq(md) {
    // padding-top: $sp-8;
    padding-top: $sp-10;
    overflow-y: auto;
    flex: 1 1 auto;
  }
}

// stylelint-disable selector-no-type
body {
  position: relative;
  padding-bottom: $sp-10;

  @include mq(md) {
    position: static;
    padding-bottom: 0;
  }
}
// stylelint-enable selector-no-type

.site-footer {
  position: absolute;
  bottom: 0;
  padding-top: $sp-4;
  padding-bottom: $sp-4;

  @include mq(md) {
    // position: static;

    // align-self: flex-end;
    // justify-self: end;
    background-color: $sidebar-color;
  }
}
