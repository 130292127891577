//
// Utility classes for lists
//

// stylelint-disable primer/selector-no-utility

.list-style-none {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
}
