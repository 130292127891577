//
// Main nav, breadcrumb, etc...
//

.site-title {
  display: block;
  flex: 1 1 auto;
  color: $body-heading-color;
  background-color: $sidebar-color;

  @include mq(md) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 101;
    height: 60px;
    padding-top: $sp-4;
    border-bottom: $border $border-color;
  }
}

.navigation-list {
  padding: 0;
  margin-top: $sp-4;
  margin-bottom: 0;
  list-style: none;

  @include mq(md) {
    margin-top: 0;
  }
}

.navigation-list-child-list {
  padding-left: $sp-3;
  list-style: none;

  .navigation-list-link {
    color: $nav-child-link-color;
  }

  .navigation-list-item {
    position: relative;

    &::before {
      position: absolute;
      margin-top: 0.3em;
      margin-left: -0.8em;
      color: rgba($body-text-color, 0.3);
      content: "- ";
    }

    &.active {
      &::before {
        color: $body-text-color;
      }
    }
  }
}

.navigation-list-item {
  @include fs-4;
  margin: 0;

  @include mq(md) {
    @include fs-3;
  }

  .navigation-list-child-list {
    display: none;
  }

  &.active {
    .navigation-list-child-list {
      display: block;
    }
  }
}

.navigation-list-link {
  display: block;
  padding-top: $sp-1;
  padding-bottom: $sp-1;

  &.active {
    font-weight: 600;
    // color: $body-heading-color;
    color: white;
    background-color: rgba(242,235,191,.5);
    text-decoration: none;
  }
}

// Small screen nav

.main-nav,
.aux-nav {
  display: none;

  &.nav-open {
    display: block;
  }
  @include mq(md) {
    display: block;
  }
}

.navigation-list-toggle {
  position: absolute;
  right: $sp-4;

  @include mq(md) {
    display: none !important;
  }
}

// Breadcrumb nav
.breadcrumb-nav {
  @include mq(md) {
    margin-top: -$sp-4;
  }
}

.breadcrumb-nav-list {
  padding-left: 0;
  margin-bottom: $sp-3;
  list-style: none;
}

.breadcrumb-nav-list-item {
  display: table-cell;
  @include fs-2;

  &::before {
    display: none;
  }

  &::after {
    display: inline-block;
    margin-right: $sp-2;
    margin-left: $sp-2;
    color: $grey-dk-000;
    content: "/";
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
}
